.account-header {
  display: flex;
  margin: 0 0 0 0.5em;
}

.horizontal-flex-container {
  padding: 0.5em;
  display: flex;
  gap: 2em;
}

.horizontal-flex-container .item {
  cursor: pointer;
  justify-content: center;
  padding: 0.2em 1em;
  border-radius: 4px;
}

.horizontal-flex-container .highlight {
  background-color: #ffa500;
}

.horizontal-flex-container .item:hover {
  background-color: gray;
}

.notification {
  text-decoration: none;
  padding: 7px 8px;
  position: relative;
}

.notification .badge {
  position: absolute;
  top: -5px;
  right: -1px;
  color: red;
}

.basic-card-container {
  margin: var(--material-page-margin);
  background-color: whitesmoke;
  padding: 4px;
  border-radius: 4px;
  border: 2px solid transparent;
}

.activity-container,
.account-post-container {
  cursor: pointer;
}

.activity-container:hover,
.account-post-container:hover {
  border: 2px solid lightslategray;
}

.activity-container .title {
  display: flex;
  margin-bottom: 0.1em;
  font-weight: bold;
}

.account-post-container .post-title-container {
  display: flex;
  flex-direction: column;
  row-gap: 0.1em;
  margin-bottom: 0.1em;
}

.account-post-container .post-title-container .title {
  font-weight: bold;
  font-size: 17px;
}

.account-post-container .post-title-container .course {
  color: lightslategray;
}

.activity-container .badge {
  color: red;
  margin: 0 0.2em;
}

.activity-container .content,
.account-post-container .content {
  align-self: start;
  border-radius: 0 0 4px 4px;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  content: "";
  position: relative;
  white-space: pre-line;
}

.activity-container .content:before,
.account-post-container .content:before {
  content: "";
  border-radius: 0 0 4px 4px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(transparent 50px, lightgray);
}

.delete-account {
  color: red;
}

.delete-account-modal {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 40%;
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

/* .Overlay { */
  /* position: fixed; */
  /* top: 0; */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  /* background-color: rgba(255, 255, 255, 0.75); */
/* } */
