.wave {
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(214% + 1.3px);
  height: 133px;
}

.wave .shape-fill {
  fill: navy;
}

.feature-container,
.header-container {
  display: grid;
  justify-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-rows: auto;
  grid-template-areas: "description image";
  /* border: 3px solid orange; */
}

.header-container {
  grid-template-areas: "description image";
  grid-template-columns: 1fr 2fr;
  padding-bottom: 10px;
  background-color: navy;
  /* border: 3px solid green; */
}

.feature-container {
  margin: 0.5em;
  margin-bottom: 20px;
}

.feature-container:nth-child(even) {
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "description image";
}
.feature-container:nth-child(odd) {
  grid-template-columns: 2fr 1fr;
  grid-template-areas: "image description";
}

.feature-description {
  grid-area: description;
  /* max-width: 585px; */
  /* margin-left: 10%; */
  /* margin-right: 10%; */
  /* border: 3px solid orange; */
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.feature-description > h2,
.feature-description > .title {
  margin-top: 24px;
  margin-bottom: 16px;
  font-size: 40px;
  line-height: 52px;
  font-weight: 700;
  letter-spacing: -0.2px;
  /* border: 3px solid red; */
}

.feature-description > .title {
  color: whitesmoke;
}

.feature-description > h2 {
  color: navy;
}

.feature-description > div,
.feature-description > .header-body {
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 500;
}

/* .feature-description > div { */
/* border: 3px solid lightcyan; */
/* } */

.feature-description .header-body {
  /* border: 3px solid magenta; */
  color: lightgrey;
}

.feature-description .header-body > a {
  color: lightgrey;
  text-decoration: none;
}

.feature-image {
  grid-area: image;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5em;
}

.feature-image > img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  box-shadow: 0 0 0.25em 0.25em rgba(0, 0, 0, 0.25);
}
