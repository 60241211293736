.my-courses-header-container {
  background-color: transparent;
  margin: 1em;
}

.my-courses-header-container > h1 {
  color: navy;
  text-align: center;
}

.my-courses-container {
  /* display: flex; */
  /* flex-direction: column */
  margin: 0.20em;
}

.link {
  text-decoration: none;
}

.my-courses-card {
  /* margin: 0.2em 0.2em; */
  /* background-color: lightgreen; */
  /* max-width: 960; */
  padding: 0.2em;
  cursor: pointer;
  border: 2px solid transparent;
  display: grid;
  grid-template-columns: 100% auto;
  /* grid-template-rows: 75% auto; */
  grid-template-areas:
    "course-title course-code"
    "course-title course-section"
    "course-title course-semester";
}

.my-courses-card:hover {
/* .my-courses-container:hover { */
  /* border: 2px solid lightslategray; */
  background-color: lightslategray;
}

.non-course-code {
  /* background-color: #22653b; */
  background-color: whitesmoke;
  color: navy;
  text-align: center;
}

.my-courses-card .course-code {
  grid-area: course-code;
}

.my-courses-card .course-title {
  grid-area: course-title;
  color: whitesmoke;
  font-size: xx-large;
  background-color: navy;
  text-align: center;
}

.my-courses-card .course-section {
  grid-area: course-section;
}

.my-courses-card .course-semester {
  grid-area: course-semester;
}

.add-more-courses {
  margin: 0.40em;
  cursor: pointer;
}
