.post-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 0.5em;
  grid-template-areas: "vote-container post-container";
}

.post-layout .post-container{
  grid-area: post-container;
  display: grid;
  grid-template-areas:
    "content content"
    "actions-container info";
}

.post-container .content {
  grid-area: content;
  margin-top: 0;
  margin-bottom: 0.5em;
  white-space: pre-line;
}

.post-container .info {
  grid-area: info;
  justify-self: end;
  color: darkgray;
}

.actions-container {
  grid-area: actions-container;
  display: flex;
  gap: 0.6em;
}

.actions-container > div {
  color: darkgray;
  cursor: pointer;
}
.actions-container > div:hover {
  color: lightgray;
}

.is-highlighted {
  /* background: linear-gradient(lightgoldenrodyellow, transparent); */
  background-color: lightgoldenrodyellow;
}

.was-highlighted {
  background-color: transparent;
  transition: background-color 1s linear;
}
