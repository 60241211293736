:root {
  --material-page-margin: 0.5em 0.5em 0.5em 0.5em;
}

body {
  background: darkgray;
}

.close-modal {
  float: right;
}

.title-container {
  background-color: transparent;
  margin: 1em;
}

.title-container > h1 {
  color: navy;
  text-align: center;
}

.body-container {
  margin: var(--material-page-margin);
}

.form-field {
  display: flex;
  flex-direction: column;
  row-gap: 0.5em;
  margin-bottom: 0.5em;
}

.form-error {
  color: red;
}

.basic-link, .basic-link:visited {
  text-decoration: none;
  color: darkblue;
}
