/* .forum { */
/* position: absolute; */
/* height: 100%; */
/* width: 100%; */
/* overflow-y: scroll; */
/* } */

.forum-options-container {
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0.5em;
  margin-bottom: 0.5em;
  display: flex;
  justify-content: center;
  gap: 0.5em;
  flex-direction: column;
}

.forum-options-item {
  display: flex;
  gap: 0.5em;
  justify-content: center;
}

.forum-options-item > button {
  flex: 1;
  /* background-color: purple; */
  cursor: pointer;
}

/* use grid  */
.forum-card {
  margin: 0.5em 0em;
  padding: 0.5em;
  background-color: whitesmoke;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-areas:
    "score header"
    ". content";
}

.forum-card:hover {
  border: 2px solid lightslategray;
}

.forum-card .content {
  grid-area: content;
  align-self: start;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  content: "";
  position: relative;
  white-space: pre-line;
}

.forum-card .content:before {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: linear-gradient(transparent 150px, lightgray);
}

.forum-card .score {
  grid-area: score;
}

.forum-card .header {
  grid-area: header;
  margin-bottom: 0.5em;
}

.forum-card .header h3 {
  align-self: start;
  display: inline;
}

.forum-card .header span {
  font-size: small;
  margin-left: 0.5em;
  border-radius: 4px;
  display: inline-block;
}

.forum-card .header span.question {
  background-color: #9CFFCF;
}

.forum-card .header span.note {
  background-color: #feff9c;
}

.forum-card .header span.typo {
  background-color: #FFCCCC;
}

.forum-card .header span.answer {
  background-color: #9D9CFF;
}

.forum-card .header span.comment {
  background-color: #6F7E90;
}

.forum-card .header span:first-letter {
  text-transform: capitalize;
}

.forum-card .header span:hover {
  background-color: lightgray;
}

.forum-modal {
  display: grid;
  margin: 0.2em 1em 1em;
}

.forum-modal .close-modal > button {
  float: right;
}

.modal-content {
  position: absolute;
  background-color: white;
  left: 44%;
  right: 0.5em;
  overflow: auto;
  top: 0;
  bottom: 0px;
  /* -webkit-ovWebkitOverflowScrolling: touch; */
  border: 1px solid #ccc;
  background: "#fff";
  outline: none;
  padding: 1px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.create-forum-modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.create-forum-modal > button {
  align-self: end;
  margin-bottom: 1em;
}
