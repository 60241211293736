/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */
.material-header-container {
  margin: var(--material-page-margin);
  display: grid;
  grid-template-areas: "title date";
}

.material-header-container .date {
  grid-area: date;
  /* background-color: blue; */
  color: navy;
  text-align: right;
}

.material-header-container .title {
  grid-area: title;
  text-align: right;
  /* background-color: green; */
  color: navy;
}

.material-container {
  margin: 0.4em;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "page forum";
  column-gap: 0.5em;
  background-color: lightgray;
  border: 0.2em solid lightgray;
}

.material-container .page {
  grid-area: page;
}

.material-container .forum {
  grid-area: forum;
  overflow-y: auto;
  height: 0;
  min-height: 100%;
}

.search-container {
  margin: 0.4em;
  background-color: lightgray;
  padding: 0.2em;
}

.search-container .search-box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.search-box .search-text {
  width: 310px;
  height: 25px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  padding: 20;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

[role="button"] {
  cursor: pointer;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
/* this causes the button to show above the modal  */
  /* z-index: 3; */
.page-item.active .page-link {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}
.page-link {
  padding: 0.375rem 0.75rem;
}
.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
