.my-courses-header-container > h2 {
  color: navy;
  text-align: center;
}

.courses-container {
  margin: 0.2em;
}

.link {
  text-decoration: none;
}

.basic-card {
  padding: 0.2em;
  cursor: pointer;
}

.basic-card:hover {
  background-color: lightslategray;
}

.course-container {
  display: grid;
  grid-template-columns: 25% auto;
  /* grid-template-rows: 75% auto; */
  grid-template-areas: "date title";
}

.course-container .date {
  padding: 0.2em;
  grid-area: date;
  font-size: large;
  color: whitesmoke;
  background-color: navy;
}
.course-container .title {
  padding: 0.2em;
  grid-area: title;
  background-color: whitesmoke;
  color: navy;
}

.add-material {
  margin: 0.4em;
  cursor: pointer;
}

.modal-container {
  /* background-color: green; */
  display: grid;
  grid-template-columns: auto 10%;
  grid-template-areas:
    "action-title close-modal"
    "upload-material upload-material";
}

.modal-container .close-modal {
  grid-area: close-modal;
  margin: 0.4em;
}

.action-title {
  /* background-color: blue; */
  grid-area: action-title;
  margin: var(--material-page-margin);
  font-weight: bold;
}

.upload-material {
  grid-area: upload-material;
  margin: 0.4em;
  display: inline-block;
}

.sections {
  border: 2px solid lightgray;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sections > div {
  display: flex;
  align-items: center;
  gap: 1em;
}

.sections > div > .info{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1em;
}
