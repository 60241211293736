.answer-layout {
  margin-bottom: 1em;
  border-bottom: 1px solid grey;
  display: grid;
  grid-template-areas:
    "post-container"
    "comments-container";
}
/* todo: make add-button button prettier  */

.answers-options {
  display: flex;
  justify-content: end;
  margin-bottom: 1em;
}

.basic-button {
  color: darkgray;
  cursor: pointer;
}

.basic-button:hover {
  color: lightgray;
}

.answer-layout .post-container {
  grid-area: post-container;
}

/* TODO: make margin-left allign with margin in post in post-container */
.answer-layout .comments-container {
  grid-area: comments-container;
  margin-left: 2em;
  margin-bottom: 1em;
}

.comments-container .add-comment {
  display: inline-block;
  color: darkgray;
  cursor: pointer;
}
.comments-container .add-comment:hover {
  color: lightgray;
}

.answer-layout .is-highlighted {
  /* background: linear-gradient(lightgoldenrodyellow, transparent); */
  background-color: lightgoldenrodyellow;
}

.answer-layout .was-highlighted {
  background-color: transparent;
  transition: background-color 1s linear;
}
