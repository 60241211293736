.original-post-container {
  grid-area: original-post-container;
  display: grid;
  grid-template-areas:
    "header"
    "post";
}

.original-post-container > .header {
  grid-area: header;
}

.original-post-container > .post {
  grid-area: post;
}
