.vote-container,
.vote-container-comment {
  margin: 0 0.2em 0 0;
  display: flex;
}

.vote-container {
  align-items: center;
  flex-direction: column;
}

.vote-container-comment {
  padding: 0 0.2em;
}

.default-vote-up,
.default-vote-down {
  cursor: pointer;
  font-size: 2em;
  color: darkgrey;
}

.vote-container-comment > .default-vote-up,
.vote-container-comment > .vote-up {
  transform: translateY(-3px);
}

.vote-container-comment > .default-vote-down,
.vote-container-comment > .vote-down {
  transform: translateY(-6px);
}

.vote-up {
  color: #228b22;
}

.vote-down {
  color: orange;
}

.count {
  font-weight: 700;
  font-size: 1.2em;
  color: darkgrey;
}

.vote-container-comment > .count {
  padding: 0 0.2em;
}

