header {
  background-color: navy;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "martlet-container options";
  height: 4em;
}

header .martlet-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-area: martlet-container;
  margin-left: 1em;
}

header .options-container {
  grid-area: options;
  display: flex;
  justify-content: flex-end;
  gap: 1.25em;
  margin-right: 1em;
}

.martlet-container .martlet {
  color: whitesmoke;
  text-decoration: none;
}

.options-container .option {
  align-items: center;
  display: flex;
  font-weight: bold;
  color: whitesmoke;
  background-color: inherit;
}

.options-container .option {
  text-decoration: none;
}

.options-container .option:hover {
  background: lightslategray;
}

.logout {
  cursor: pointer;
}
