.posts-layout {
  display: grid;
  grid-template-areas:
    "original-post-container"
    "answer-form-container";
}

.post-form-container {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.post-form-container .post-form-textarea-content {
  height: 100px;
  resize: vertical;
}

.post-form-container .post-form-textarea-title {
  resize: vertical;
}
